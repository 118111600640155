<template>
  <div class="page">
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="page !== null">
      <page-header :title="page.name" :icon="page.icon" :links="getLinks"></page-header>
      <div ref="printMe">
        <div class="hide-here">
          <b><i :class="page.icon"></i> {{ page.name }}</b>
        </div>
        <div v-show="!isLoading(loadingFilterName) && page.apiFilters && page.apiFilters.length" class="filter-bar">
          <div
            v-for="apiFilter of page.apiFilters"
            :key="apiFilter.id"
          >
            <b-row>
              <b-col cols="3" v-if="getApiFilterCategories(apiFilter).length">
                <b-form-select
                  :id="apiFilter.apiVariable + 'categories'"
                  :value="getApiFilterCategory(apiFilter)"
                  @change="onApiFilterCategoryChanged(apiFilter, $event)"
                >
                  <b-form-select-option :value="null"></b-form-select-option>
                  <b-form-select-option
                    :value="elt"
                    v-for="elt in getApiFilterCategories(apiFilter)"
                    :key="elt.id"
                  >
                    {{ elt.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-col>
              <b-col cols="3" class="no-print" v-else>
                <h3>{{ apiFilter.name }}</h3>
              </b-col>
              <b-col cols="3" v-if="getApiFilterYears(apiFilter).length > 1" class="no-print">
                <b-form-select
                  :id="'year' + apiFilter.apiVariable"
                  @change="onApiFilterYearChanged(apiFilter, $event)"
                >
                  <b-form-select-option :value="null"></b-form-select-option>
                  <b-form-select-option :value="year" v-for="year in getApiFilterYears(apiFilter)" :key="year.id">
                    {{ year.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-col>
              <b-col cols="3" v-if="getApiFilterYears(apiFilter).length === 1" class="no-print">
                <b-form-select
                  id="'year1"
                  :value="getApiFilterYears(apiFilter)[0].id"
                  disabled
                >
                  <b-form-select-option :value="getApiFilterYears(apiFilter)[0].id">
                    {{ getApiFilterYears(apiFilter)[0].name }}
                  </b-form-select-option>
                </b-form-select>
              </b-col>
              <b-col>
                <div v-if="apiFilter.isMultiple">
                  <b-row>
                    <b-col>
                      <div class="no-print">
                        <b-input
                          class="small-input"
                          type="text"
                          :placeholder="'Entrez un texte pour filtrer des choix ' + apiFilter.name"
                          v-model="apiFilterText[apiFilter.apiVariable]"
                        >
                        </b-input>
                      </div>
                    </b-col>
                    <b-col cols="3" class="text-right">
                      <a
                        :class="{ disabled: !apiFilterChanged, }"
                        class="btn btn-sm btn-primary"
                        href
                        @click.prevent="refresh()"
                      >
                        <i class="fa fa-refresh"></i>
                        Voir la liste
                      </a>
                    </b-col>
                  </b-row>
                </div>
                <div v-else>
                  <div class="no-print">
                    <b-form-select
                      :id="apiFilter.apiVariable"
                      :value="getApiFilterValue(apiFilter)"
                      @change="onApiFilterChanged(apiFilter, $event)"
                    >
                      <b-form-select-option
                        :value="elt"
                        v-for="elt in getApiFilterChoicesEx(apiFilter)"
                        :key="elt.id"
                      >
                        {{ elt.name }}
                      </b-form-select-option>
                    </b-form-select>
                  </div>
                  <div v-if="apiFilterNames" class="hide-here">
                    {{ apiFilterNames }}
                  </div>
                </div>
              </b-col>
            </b-row>
            <div v-if="apiFilter.isMultiple" class="multiple-filter">
              <div class="no-print">
                <check-box-select
                  inline
                  span
                  :id="apiFilter.apiVariable"
                  :choices="getApiFilterChoicesEx(apiFilter)"
                  @changed="onApiFilterChoicesChanged(apiFilter, $event)"
                ></check-box-select>
                <div v-if="apiFilter.onlyLastAdhesion">
                  <b-checkbox
                    inline
                    id="distinct"
                    v-model="distinct"
                  >
                    Afficher seulement la dernière adhésion
                  </b-checkbox>
                </div>
              </div>
              <div class="hide-here">
                {{ selectedChoicesText }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="youthHomeId">
          <div class="text-right no-print">
            <a href @click.prevent="viewMoreFilters = !viewMoreFilters">
              <span v-if="viewMoreFilters">Moins de filtres</span>
              <span v-else>Plus de filtres</span>
            </a>
          </div>
          <div class="sub-filter no-print" v-if="viewMoreFilters">
            <b-row>
              <b-col>
                <youth-home-filter
                  :forced-youth-home="youthHomeId"
                  :block="false"
                  @changed="onFilterChanged"
                ></youth-home-filter>
              </b-col>
              <b-col cols="3">
                <b-form-group label-for="seanceDate" label="Présent le">
                  <b-input
                    v-model="seanceDate"
                    type="date"
                    id="seanceDate"
                    name="seanceDate"
                    @change="onSeanceDateChanged"
                  ></b-input>
                  <div class="small-text">
                    <b-form-checkbox id="morning" v-model="morning" @change="refresh()">
                      Matin
                    </b-form-checkbox>
                    <b-form-checkbox id="lunch" v-model="lunch" @change="refresh()">
                      Repas
                    </b-form-checkbox>
                    <b-form-checkbox id="afternoon" v-model="afternoon" @change="refresh()">
                      Après-midi
                    </b-form-checkbox>
                    <b-form-checkbox id="evening" v-model="evening" @change="refresh()">
                      Soirée
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="hide-here">
          {{ extraFiltersName }}
        </div>
        <loading-gif :loading-name="loadingDataName"></loading-gif>
        <div v-if="!isLoading(loadingDataName) && !loaded" class="empty-text">
          Veuillez sélectionner un élément dans la liste déroulante
        </div>
        <div class="page-table" v-show="!isLoading(loadingDataName) && loaded" ref="excelMe">
          <x-table
            :columns="columns"
            :items="items"
            key-field="key"
            :show-counter="true"
            :verbose-name="verboseName"
            :verbose-name-plural="verboseNamePlural"
            @selectionChanged="onSelectionChanged($event)"
          ></x-table>
        </div>
      </div>
    </div>
    <confirm-modal
      name="call-page-action"
      :icon="confirmActionIcon"
      :object="selectedAction"
      :title="confirmActionTitle"
      :text="confirmActionText"
      @confirmed="onConfirmCallAction"
    >
    </confirm-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import PageHeader from '@/components/Layout/PageHeader'
import LoadingGif from '@/components/Controls/LoadingGif'
import XTable from '@/components/Controls/Table/Table'
import YouthHomeFilter from '@/components/Youth/YouthHomeFilter'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import store from '@/store'
import { FieldType } from '@/types/fields'
import { makePage, PageColumnLink, PageColumnType } from '@/types/pages'
import { makeIndividual, makeEntity } from '@/types/people'
import { makeSchoolYear } from '@/types/schools'
import { BackendApi, openDocument } from '@/utils/http'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect.vue'
import { dateToString } from '@/filters/texts'
import { existsIn } from '@/utils/arrays'

export default {
  name: 'page',
  mixins: [BackendMixin],
  components: {
    ConfirmModal,
    CheckBoxSelect,
    YouthHomeFilter,
    PageHeader,
    LoadingGif,
    XTable,
  },
  props: {
    slug: String,
  },
  data() {
    return {
      loadingName: 'pageDetail',
      loadingDataName: 'pageData',
      loadingFilterName: 'pageFilter',
      page: null,
      allItems: [],
      verboseName: '',
      verboseNamePlural: '',
      apiFilterValues: {},
      apiFilterChoices: {},
      apiFilterCategory: {},
      apiFilterText: {},
      loaded: false,
      apiFilterYears: {},
      viewMoreFilters: false,
      selectedPeriods: [],
      selectedTypes: [],
      seanceDate: null,
      selectedChoices: [],
      selectedItems: [],
      selectedAction: null,
      columns: [],
      distinct: false,
      apiFilterChanged: false,
      morning: false,
      lunch: false,
      afternoon: false,
      evening: false,
    }
  },
  computed: {
    selectedChoicesText() {
      return this.selectedChoices.map(elt => elt.name).join(' - ')
    },
    confirmActionTitle() {
      if (this.selectedAction) {
        return this.selectedAction.title
      }
      return ''
    },
    confirmActionIcon() {
      if (this.selectedAction) {
        return this.selectedAction.icon
      }
      return ''
    },
    confirmActionText() {
      if (this.selectedAction) {
        return this.selectedAction.confirmText
      }
      return ''
    },
    getLinks() {
      let links = []
      if (this.page && this.page.slug) {
        links = [
          {
            id: 1,
            label: 'Excel',
            callback: this.excelMe,
            icon: 'far fa-file-excel',
            cssClass: this.isLoading(this.loadingDataName) ? 'btn-secondary disabled' : 'btn-secondary',
          },
          {
            id: 2,
            label: 'Pdf',
            callback: this.printMe,
            icon: 'fa fa-file-pdf',
            cssClass: this.isLoading(this.loadingDataName) ? 'btn-secondary disabled' : 'btn-secondary',
          }
        ]
        for (const action of this.page.actions.filter(elt => !elt.disabled)) {
          if (this.hasAllPerms(action.permissions)) {
            let cssClass = 'btn-secondary'
            if (this.isLoading(this.loadingDataName) || this.selectedItems.length === 0) {
              cssClass += ' disabled'
            }
            links.push(
              {
                id: links.length + 1,
                label: action.title,
                callback: this.onConfirmAction,
                icon: action.icon,
                cssClass: cssClass,
                arg: action,
              }
            )
          }
        }
      }
      return links
    },
    youthHomeId() {
      if (this.page && this.apiFilterValues) {
        for (let apiFilter of this.page.apiFilters) {
          if (apiFilter.apiVariable === 'youth_home') {
            if (apiFilter.isMultiple) {
              const values = this.apiFilterValues[apiFilter.apiVariable]
              if (values) {
                return +(values.split('-')[0])
              }
            } else {
              if (this.apiFilterValues[apiFilter.apiVariable]) {
                return this.apiFilterValues[apiFilter.apiVariable].id
              }
            }
          }
        }
      }
      return 0
    },
    activityMenus() {
      return store.getters.config.activityMenus
    },
    apiFilterNames() {
      let names = []
      if (this.page && this.apiFilterValues) {
        for (let apiFilter of this.page.apiFilters) {
          if (this.apiFilterValues[apiFilter.apiVariable]) {
            names.push(this.apiFilterValues[apiFilter.apiVariable].name)
          }
        }
      }
      return names.join(', ')
    },
    extraFiltersName() {
      let texts = []
      if (this.selectedPeriods.length) {
        texts = texts.concat(this.selectedPeriods.map(elt => '' + elt.name))
      }
      if (this.selectedTypes.length) {
        texts = texts.concat(this.selectedTypes.map(elt => '' + elt.name))
      }
      if (this.seanceDate) {
        texts.push(dateToString(this.seanceDate))
      }
      if (this.morning) {
        texts.push('Matin')
      }
      if (this.lunch) {
        texts.push('Repas')
      }
      if (this.afternoon) {
        texts.push('Après-midi')
      }
      if (this.evening) {
        texts.push('Soirée')
      }
      return texts.join(' - ')
    },
    items() {
      if (!this.distinct) {
        return this.allItems
      } else {
        const items = []
        const itemsMap = new Map()
        for (const item of this.allItems) {
          const key = '' + (item['individual_id'] || 0) + ':' + (item['entity_id'] || 0)
          item.key = key
          if (!itemsMap.has(key)) {
            itemsMap.set(key, item)
            items.push(item)
          }
        }
        return items
      }
    },
  },
  watch: {
    slug: function() { this.onLoaded() },
    viewMoreFilters: function() {
      if (!this.viewMoreFilters) {
        this.onFilterChanged({ periods: [], seanceTypes: [], })
        this.seanceDate = null
        this.morning = false
        this.lunch = false
        this.afternoon = false
        this.evening = false
      }
    },
  },
  mounted() {
    this.onLoaded()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onLoaded() {
      await this.loadPage()
      for (let apiFilter of this.page.apiFilters) {
        await this.loadFilterValues(apiFilter)
      }
      this.buildFilter()
      const hasFilter = Object.keys(this.apiFilterValues).length
      if (!this.page.loadOnFilter || hasFilter) {
        await this.loadPageData(this.getDataQueryString())
      }
    },
    async loadPage() {
      this.page = null
      this.startLoading(this.loadingName)
      let url = '/api/page-detail/' + this.slug + '/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.page = makePage(resp.data)
        this.columns = this.getColumns()
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadPageData(queryString = '') {
      if (this.page.apiUrl) {
        this.startLoading(this.loadingDataName)
        let url = this.page.apiUrl
        const backendApi = new BackendApi('get', url + queryString)
        try {
          const resp = await backendApi.callApi()
          this.allItems = resp.data.items.map(
            (elt, index) => {
              if (!elt.id) {
                elt.id = elt['individual_id'] || elt['entity_id'] || index
              }
              return elt
            }
          )
          this.verboseName = resp.data.verbose_name || ''
          this.verboseNamePlural = resp.data.verbose_name_plural || ''
          this.loaded = true
          this.columns = this.getColumns()
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingDataName)
      }
    },
    async loadFilterValues(apiFilter) {
      this.startLoading(this.loadingFilterName)
      let url = apiFilter.apiUrl + '?'
      if (apiFilter.queryString) {
        url += apiFilter.queryString
      }
      const menu = this.getApiFilterCategory(apiFilter)
      if (menu) {
        url += '&category=' + menu.obj.category
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.apiFilterChoices[apiFilter.apiVariable] = resp.data
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingFilterName)
    },
    getApiFilterChoices(apiFilter) {
      return this.apiFilterChoices[apiFilter.apiVariable] || []
    },
    getApiFilterYear(apiFilter) {
      return this.apiFilterYears[apiFilter.apiVariable] || null
    },
    getApiFilterChoicesEx(apiFilter) {
      let choices = this.getApiFilterChoices(apiFilter)
      const onlyYear = this.getApiFilterYear(apiFilter)
      if (onlyYear) {
        choices = choices.filter(elt => elt.school_year.id === onlyYear.id)
      }
      const filterText = this.apiFilterText[apiFilter.apiVariable] || ''
      if (filterText) {
        choices = choices.filter(elt => elt.name.toLowerCase().includes(filterText.toLowerCase()))
      }
      return choices
    },
    getApiFilterYears(apiFilter) {
      const schoolYears = []
      for (let elt of this.getApiFilterChoices(apiFilter)) {
        if (elt.school_year) {
          if (schoolYears.map(elt => elt.id).indexOf(elt.school_year.id) < 0) {
            schoolYears.push(makeSchoolYear(elt.school_year))
          }
        }
      }
      return schoolYears
    },
    getDataQueryString() {
      let queryString = '?'
      for (let apiFilter of this.page.apiFilters) {
        let value = this.apiFilterValues[apiFilter.apiVariable] || null
        if (apiFilter.isMultiple) {
          queryString += ('&' + apiFilter.apiVariable + '=' + (value || 0))
        } else {
          if (value) {
            queryString += ('&' + apiFilter.apiVariable + '=' + value.id)
          }
        }
      }
      if (this.selectedPeriods.length) {
        queryString += ('&periods=' + this.selectedPeriods.map(elt => '' + elt.id).join(','))
      }
      if (this.selectedTypes.length) {
        queryString += ('&seance_types=' + this.selectedTypes.map(elt => '' + elt.id).join(','))
      }
      if (this.seanceDate) {
        queryString += ('&seance_date=' + this.seanceDate)
      }
      if (this.morning) {
        queryString += '&morning=1'
      }
      if (this.lunch) {
        queryString += '&lunch=1'
      }
      if (this.afternoon) {
        queryString += '&afternoon=1'
      }
      if (this.evening) {
        queryString += '&evening=1'
      }
      return queryString
    },
    getDataQueryMap() {
      let queryMap = {}
      for (let apiFilter of this.page.apiFilters) {
        let value = this.apiFilterValues[apiFilter.apiVariable] || null
        if (apiFilter.isMultiple) {
          queryMap[apiFilter.apiVariable] = (value || 0)
        } else {
          if (value) {
            queryMap[apiFilter.apiVariable] = value.id
          }
        }
      }
      if (this.selectedPeriods.length) {
        queryMap['periods'] = this.selectedPeriods.map(elt => '' + elt.id)
      }
      if (this.selectedTypes.length) {
        queryMap['seance_types'] = this.selectedTypes.map(elt => '' + elt.id)
      }
      if (this.seanceDate) {
        queryMap['seance_date'] = this.seanceDate
      }
      if (this.morning) {
        queryMap['morning'] = '1'
      }
      if (this.lunch) {
        queryMap['lunch'] = 1
      }
      if (this.afternoon) {
        queryMap['afternoon'] = 1
      }
      if (this.evening) {
        queryMap['evening'] = 1
      }
      return queryMap
    },
    async onConfirmAction(link) {
      this.selectedAction = link.arg
      this.$bvModal.show('bv-confirm-modal:call-page-action')
    },
    async onConfirmCallAction() {
      const action = this.selectedAction
      let postData = {}
      if (action.postData) {
        postData = { ...action.postData, }
      }
      postData[action.selectPostField] = this.selectedItems.map(
        elt => elt[action.selectField]
      )
      this.startLoading(this.loadingDataName)
      let url = action.apiUrl
      let isOk = false
      const backendApi = new BackendApi('post', url)
      try {
        const resp = await backendApi.callApi(postData)
        if (resp.data.message) {
          await this.addSuccess(resp.data.message)
        }
        isOk = true
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingDataName)
      if (isOk) {
        await this.refresh()
      }
    },
    onApiFilterChoicesChanged(apiFilter, value) {
      this.selectedChoices = value.choices
      const choices = value.choices.map(elt => '' + elt.id).join('-')
      this.apiFilterValues[apiFilter.apiVariable] = choices
      this.apiFilterValues = { ...this.apiFilterValues, }
      this.apiFilterChanged = true
    },
    buildFilter() {
      for (let apiFilter of this.page.apiFilters) {
        let value = this.$route.query[apiFilter.apiVariable] || null
        if (value) {
          if (apiFilter.isMultiple) {
            this.apiFilterValues[apiFilter.apiVariable] = this.setMultipleApiFilterValue(
              apiFilter, this.apiFilterValues[apiFilter.apiVariable]
            )
          } else {
            this.setApiFilterValue(apiFilter, value)
          }
        }
      }
      if (this.$route.query['periods']) {
        this.selectedPeriods = this.setMultipleApiFilterValue(this.selectedPeriods, this.$route.query['periods'])
      }
      if (this.$route.query['seance_types']) {
        this.selectedTypes = this.setMultipleApiFilterValue(this.selectedTypes, this.$route.query['seance_types'])
      }
      if (this.$route.query['seance_date']) {
        this.seanceDate = this.$route.query['seance_date']
      }
      if (this.$route.query['morning']) {
        this.morning = true
      }
      if (this.$route.query['lunch']) {
        this.lunch = true
      }
      if (this.$route.query['afternoon']) {
        this.afternoon = true
      }
      if (this.$route.query['evening']) {
        this.evening = true
      }
      this.apiFilterValues = { ...this.apiFilterValues, }
    },
    async refresh() {
      await this.loadPageData(this.getDataQueryString())
      this.apiFilterChanged = false
    },
    setApiFilterValue(apiFilter, initial) {
      for (const elt of this.getApiFilterChoicesEx(apiFilter)) {
        if (elt.id === +initial) {
          this.apiFilterValues[apiFilter.apiVariable] = elt
          break
        }
      }
      this.apiFilterValues = { ...this.apiFilterValues, }
    },
    setMultipleApiFilterValue(apiFilter, initial) {
      const initialValue = initial || ''
      const values = initialValue.split(',').map(elt => +elt)
      const selected = []
      for (const elt of this.getApiFilterChoicesEx(apiFilter)) {
        if (existsIn([elt.id], values)) {
          selected.push(elt)
        }
      }
      return selected
    },
    getApiFilterValue(apiFilter) {
      return this.apiFilterValues[apiFilter.apiVariable]
    },
    async onApiFilterChanged(apiFilter, value) {
      this.apiFilterValues[apiFilter.apiVariable] = value
      this.apiFilterValues = { ...this.apiFilterValues, }
      await this.$router.replace({ query: this.getDataQueryMap(), })
      await this.loadPageData(this.getDataQueryString())
    },
    async onApiFilterYearChanged(apiFilter, value) {
      this.apiFilterYears[apiFilter.apiVariable] = value
      this.apiFilterValues = { ...this.apiFilterValues, }
      this.apiFilterYears = { ...this.apiFilterYears, }
    },
    // async onPeriodChanged() {
    //   await this.loadPageData(this.getDataQueryString())
    // },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'page-' + this.page.slug
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/'
      if (this.page.landscape) {
        docUrl += '?landscape=1'
      }
      const docSlug = 'page-' + this.page.id
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    columnsLength(column) {
      let maxLength = 1
      for (let item of this.items) {
        if (Array.isArray(item[column.variable])) {
          const length = item[column.variable].length
          if (length > maxLength) {
            maxLength = length
          }
        } else {
          return 1
        }
      }
      return maxLength
    },
    onFilterChanged(event) {
      this.selectedPeriods = event.periods
      this.selectedTypes = event.seanceTypes
      this.loadPageData(this.getDataQueryString())
    },
    onSeanceDateChanged() {
      this.loadPageData(this.getDataQueryString())
    },
    onSelectionChanged(event) {
      this.selectedItems = event.items
    },
    getColumns() {
      let columns = []
      if (this.page.actions.length) {
        columns.push(
          { selector: true, 'name': 'selector', maxWidth: '20px', }
        )
      }
      columns = columns.concat(
        this.page.columns.reduce(
          (acc, elt) => {
            let values = []
            if (elt.columnType !== PageColumnType.Comments) {
              if (elt.field && elt.field.fieldType === FieldType.Boolean) {
                values.push('Oui')
                values.push('Non')
              }
              if (elt.field && elt.field.fieldType === FieldType.Choices) {
                for (let choice of elt.field.choices) {
                  values.push(choice.text)
                }
              }
            }
            if (elt.subColumns.length > 0) {
              const colLength = this.columnsLength(elt)
              for (let index = 0; index < colLength; index++) {
                for (let subIndex = 0; subIndex < elt.subColumns.length; subIndex++) {
                  const subField = elt.subColumns[subIndex]
                  let subLabel = ''
                  if ((elt.subColumns.length === elt.subLabels.length) && (subIndex < elt.subLabels.length)) {
                    subLabel = elt.subLabels[subIndex]
                  }
                  acc.push(
                    {
                      name: elt.textVariable + index + subField,
                      label: (subLabel || elt.label) + (index + 1),
                      values: [],
                      index: index,
                      subField: subField,
                      field: elt.textVariable,
                    }
                  )
                }
              }
            } else {
              const col = {
                name: elt.textVariable,
                label: elt.label,
                values: values,
              }
              if (elt.style.search('right') >= 0) {
                col.alignRight = true
              }
              if (elt.style.search('center') >= 0) {
                col.alignCenter = true
              }
              if (elt.columnType === PageColumnType.ShortDate) {
                col.dateFormat = 'DD/MM/YYYY'
              }
              if (elt.columnType === PageColumnType.LongDate) {
                col.dateFormat = 'ddd D MMM YYYY'
              }
              if (elt.columnType === PageColumnType.Number) {
                col.number = true
              }
              if (elt.columnType === PageColumnType.Currency) {
                col.currency = true
              }
              if (elt.columnType === PageColumnType.Email) {
                col.displayAs = 'email'
              }
              if (elt.columnType === PageColumnType.Phone) {
                col.displayAs = 'phone'
              }

              col.maxWidth = elt.maxWidth
              if (elt.link === PageColumnLink.IndividualSidebar) {
                col.onClick = (item) => {
                  if (item['individual_id']) {
                    const individual = makeIndividual({ id: item['individual_id'], })
                    this.showIndividualSidebar(individual)
                  }
                }
                col.isLink = () => { return item['individual_id'] }
                col.linkUrl = (item) => {
                  if (item['entity_id']) {
                    return router.resolve(
                      {
                        name: 'families-detail',
                        params: { entityId: item['entity_id'], },
                        query: { individual: item['individual_id'], },
                      }
                    ).href
                  }
                }
              } else if (elt.link === PageColumnLink.EntitySidebar) {
                col.onClick = (item) => {
                  if (item['entity_id']) {
                    const entity = makeEntity({ id: item['entity_id'], })
                    this.showEntitySidebar(entity)
                  }
                }
                col.isLink = () => { return item['entity_id'] }
                col.linkUrl = (item) => {
                  if (item['entity_id']) {
                    return router.resolve(
                      {
                        name: 'families-detail',
                        params: { entityId: item['entity_id'], },
                        query: { individual: item['individual_id'], },
                      }
                    ).href
                  }
                }
              } else if (elt.link === PageColumnLink.EntityPage) {
                col.onClick = (item) => {
                  const query = {}
                  if (item['individual_id']) {
                    query.individual = item['individual_id']
                  }
                  if (item['entity_id']) {
                    router.push(
                      {
                        name: 'families-detail',
                        params: { entityId: item['entity_id'], },
                        query: query,
                      }
                    )
                  }
                }
                col.isLink = (item) => { return (item['entity_id']) }
                col.linkUrl = (item) => {
                  const query = {}
                  if (item['individual_id']) {
                    query.individual = item['individual_id']
                  }
                  if (item['entity_id']) {
                    return router.resolve(
                      {
                        name: 'families-detail',
                        params: { entityId: item['entity_id'], },
                        query: query,
                      }
                    ).href
                  }
                }
              }
              acc.push(col)
            }
            return acc
          },
          []
        )
      )
      return columns
    },
    getApiFilterCategories(apiFilter) {
      if (apiFilter.apiVariable === 'activity' && this.activityMenus.length && !apiFilter.hideCategory) {
        if (!apiFilter.queryString.includes('&category=')) {
          return this.activityMenus.map(
            elt => {
              return {
                id: elt.id,
                name: elt.label,
                obj: elt,
              }
            }
          )
        }
      }
      return []
    },
    getApiFilterCategory(apiFilter) {
      return this.apiFilterCategory[apiFilter.apiVariable] || null
    },
    async onApiFilterCategoryChanged(apiFilter, event) {
      this.apiFilterValues[apiFilter.apiVariable] = null
      this.apiFilterCategory[apiFilter.apiVariable] = event
      await this.loadFilterValues(apiFilter)
    },
  },
}
</script>

<style lang="less">
  .filter-bar {
    margin-bottom: 10px;
  }
  .filter-bar h3 {
    margin-top: 5px;
    text-align: right;
    font-weight: bolder;
  }
  .sub-filter {
    background: #ccc;
    padding: 10px;
    color: #222;
  }
  .multiple-filter {
    font-size: 12px;
    margin: 5px;
    padding: 5px;
    background: #eee;
    color: #222;
  }
</style>
